import { render, staticRenderFns } from "./TabMailComponent.vue?vue&type=template&id=e8e1f2de&scoped=true&"
import script from "./TabMailComponent.vue?vue&type=script&lang=js&"
export * from "./TabMailComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8e1f2de",
  null
  
)

export default component.exports