<template>
  <div>
    <b-row>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-start">
          <b-button
            v-if="accessPermissions('providers.mail.design.store')"
            v-b-modal.modal-mails
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Nuevo</span>
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-form-input
              v-if="accessPermissions('providers.mail.design.list.index')"
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar..."
              class="d-inline-block"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="accessPermissions('providers.mail.design.list.index')">
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :items="rows"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(action)="data">
            <b-dropdown
              v-if="accessPermissions('providers.mail.design.update') || accessPermissions('providers.mail.design.destroy')"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="SettingsIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="accessPermissions('providers.mail.design.update')"
                @click="onHandleEdit(data.item.id)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="accessPermissions('providers.mail.design.destroy')"
                @click="onHandleDelete(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="accessPermissions('providers.mail.design.list.index')">
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            label="Por Página"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="custom-search d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-if="id === undefined ? accessPermissions('providers.mail.design.update') : accessPermissions('providers.mail.design.destroy')"
      id="modal-mails"
      ref="mails-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      :title="id === undefined ? 'Agregar un nuevo correo' : 'Actualizar correo'"
      @hidden="hide"
    >
      <MailFormComponent
        :id="id"
        @setHiddenModal="hide"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
} from 'bootstrap-vue'
import MailFormComponent from './MailFormComponent.vue'
import ProvidersService from '../../services/ProvidersService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import { getItem, setItem } from '@/libs/storage'

export default {
  name: 'MailComponent',
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    MailFormComponent,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 20, 50, 100],
      totalRows: 0,
      currentPage: 1,
      filter: null,
      fields: [
        {
          key: 'name', label: 'Proveedor',
        },
        {
          key: 'web', label: 'Web',
        },
        {
          key: 'webmail', label: 'WebMail',
        },
        {
          key: 'user', label: 'Usuario',
        },
        {
          key: 'password', label: 'Contraseña',
        },
        {
          key: 'action', label: 'Acción',
        },
      ],
      rows: [],
      id: undefined,
    }
  },
  watch: {
    async isActive() {
      await this.onHandleList()
    },
    filter(value) {
      setItem('search_designers_providers_email', value)
    },
  },
  mounted() {
    this.filter = getItem('search_designers_providers_email') === undefined ? '' : getItem('search_designers_providers_email')
  },
  async created() {
    await this.onHandleList()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    hide() {
      this.id = undefined
      this.onHandleList()
      this.$refs['mails-modal'].hide()
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onHandleEdit(id) {
      if (this.accessPermissions('providers.mail.design.update')) {
        this.id = id
        this.$refs['mails-modal'].show()
      }
    },
    async onHandleDelete(data) {
      if (this.accessPermissions('providers.mail.design.destroy')) {
        await this.$swal({
          title: '¿Está seguro que desea eliminar el correo?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__bounceIn',
          },
          buttonsStyling: false,
        }).then(({ value }) => {
          if (value) {
            ProvidersService.handleDelete(data).then(response => {
              if (response.data.data) {
                this.onHandleList()
              }
            }).catch(error => this.errorResp(error))
          }
        })
      }
    },
    async onHandleList() {
      if (this.isActive && this.accessPermissions('providers.mail.design.index')) {
        await ProvidersService.handleList().then(({ data }) => {
          this.rows = data.data.length === 0 ? [] : data.data
          this.totalRows = data.data.length
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style scoped>

</style>
