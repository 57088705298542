<template>
  <b-card-code>
    <b-row>
      <b-col cols="12">
        <b-tabs>
          <b-tab
            :active="activeTabName === 'emails' ? true : false"
            @click="handleActiveTab('emails')"
          >
            <template #title>
              <feather-icon icon="MailIcon" />
              <span>Correos</span>
            </template>

            <TabMailComponent
              :isActive="activeTabName === 'emails' ? true : false"
            />
          </b-tab>

          <b-tab
            :active="activeTabName === 'services' ? true : false"
            @click="handleActiveTab('services')"
          >
            <template #title>
              <feather-icon icon="ServerIcon" />
              <span>Servicios</span>
            </template>

            <TabServiceComponent
              :isActive="activeTabName === 'services' ? true : false"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import TabMailComponent from './components/TabMailComponent.vue'
import TabServiceComponent from './components/TabServiceComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'ProviderPage',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardCode,
    TabMailComponent,
    TabServiceComponent,
  },
  data() {
    return {
      activeTabName: 'emails',
    }
  },
  methods: {
    handleActiveTab(value) {
      this.activeTabName = value
    },
  },
}
</script>
