<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <div class="alert bg-primary p-1">
            <div class="position-relative d-inline text-white">
              <feather-icon icon="AlertCircleIcon" />
            </div>
            <div class="position-relative d-inline text-white">
              Registrar nuevo proveedor de correo
            </div>
          </div>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-form-group
            label="Nombre del proveedor"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre del proveedor"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre del proveedor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Página web"
            label-for="web"
          >
            <b-form-input
              id="web"
              v-model="web"
              placeholder="Página web"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Webmail"
            label-for="webMail"
          >
            <b-form-input
              id="webMail"
              v-model="webMail"
              placeholder="Webmail"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Usuario"
            label-for="user"
          >
            <b-form-input
              id="user"
              v-model="user"
              placeholder="Usuario"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Contraseña</label>
            </div>
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                v-model="password"
                class="form-control-merge"
                :type="passwordFieldType"
                name="password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <div class="alert bg-primary p-1">
            <div class="position-relative d-inline text-white">
              <feather-icon icon="AlertCircleIcon" />
            </div>
            <div class="position-relative d-inline text-white">
              Configuración entrante
            </div>
          </div>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Host"
            label-for="host"
          >
            <b-form-input
              id="host"
              v-model="host"
              placeholder="Host"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Puerto"
            label-for="port"
          >
            <b-form-input
              id="port"
              v-model="port"
              placeholder="Puerto"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Encriptación"
            label-for="encryption"
          >
            <b-form-radio
              v-model="encryption"
              name="encryption"
              class="float-left mb-2"
              value="SSL"
            >
              SSL
            </b-form-radio>
            <b-form-radio
              v-model="encryption"
              name="encryption"
              class="float-left ml-2 mb-2"
              value="TLS"
            >
              TLS
            </b-form-radio>
            <b-form-radio
              v-model="encryption"
              name="encryption"
              class="float-left ml-2 mb-2"
              value="NO"
            >
              No
            </b-form-radio>
          </b-form-group>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <div class="alert bg-primary p-1">
            <div class="position-relative d-inline text-white">
              <feather-icon icon="AlertCircleIcon" />
            </div>
            <div class="position-relative d-inline text-white">
              Configuración saliente
            </div>
          </div>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Host"
            label-for="hostExit"
          >
            <b-form-input
              id="hostExit"
              v-model="hostExit"
              placeholder="Host"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Puerto"
            label-for="portExit"
          >
            <b-form-input
              id="portExit"
              v-model="portExit"
              placeholder="Puerto"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-group
            label="Encriptación"
            label-for="encryptionExit"
          >
            <b-form-radio
              v-model="encryptionExit"
              name="encryptionExit"
              class="float-left mb-2"
              value="SSL"
            >
              SSL
            </b-form-radio>
            <b-form-radio
              v-model="encryptionExit"
              name="encryptionExit"
              class="float-left ml-2 mb-2"
              value="TLS"
            >
              TLS
            </b-form-radio>
            <b-form-radio
              v-model="encryptionExit"
              name="encryptionExit"
              class="float-left ml-2 mb-2"
              value="NO"
            >
              No
            </b-form-radio>
          </b-form-group>
        </b-col>
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('providers.mail.design.store') : accessPermissions('providers.mail.design.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            @click="close"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroupAppend,
  BInputGroup,
  BFormRadio,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import ProvidersService from '../../services/ProvidersService'

export default {
  name: 'MailFormComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    BFormRadio,
  },
  mixins: [togglePasswordVisibility],
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      name: '',
      web: '',
      webMail: '',
      user: '',
      password: '',
      host: '',
      port: '',
      encryption: '',
      hostExit: '',
      portExit: '',
      encryptionExit: '',
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    async id() {
      await this.onHandleEdit()
    },
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.name = ''
      this.web = ''
      this.webMail = ''
      this.user = ''
      this.password = ''
      this.host = ''
      this.port = ''
      this.encryption = ''
      this.hostExit = ''
      this.portExit = ''
      this.encryptionExit = ''
      this.$refs.formValidation.reset()
    },
    close() {
      this.clearForm()
      this.$emit('setHiddenModal')
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('providers.mail.design.show')) {
        await ProvidersService.handleEdit(this.id).then(response => {
          this.name = response.data.data.name
          this.web = response.data.data.web
          this.webMail = response.data.data.webmail
          this.user = response.data.data.user
          this.password = response.data.data.password
          this.host = response.data.data.host
          this.port = response.data.data.port
          this.encryption = response.data.data.encryption
          this.hostExit = response.data.data.host_exit
          this.portExit = response.data.data.port_exit
          this.encryptionExit = response.data.data.encryption_exit
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const data = {
            name: this.name,
            web: this.web,
            webmail: this.webMail,
            user: this.user,
            password: this.password,
            host: this.host,
            port: this.port,
            encryption: this.encryption,
            host_exit: this.hostExit,
            port_exit: this.portExit,
            encryption_exit: this.encryptionExit,
          }

          if (this.id === undefined && this.accessPermissions('providers.mail.design.store')) {
            ProvidersService.handleStore(data).then(response => {
              if (response.data.data) {
                this.swal('El correo ha sido registrado correctamente', 'success')
                this.close()
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('providers.mail.design.update')) {
            ProvidersService.handleUpdate(this.id, data).then(response => {
              if (response.data.data) {
                this.swal('El correo ha sido actualizado correctamente', 'success')
                this.close()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
